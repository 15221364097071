class Project {
  year: string;
  name: string;
  skills: string[];
  link: string;
  linkText: string;
  github?: string;

  constructor(
    year: string,
    name: string,
    skills: string[],
    link: string,
    linkText: string,
    github?: string
  ) {
    this.year = year;
    this.name = name;
    this.skills = skills;
    this.link = link;
    this.linkText = linkText;
    this.github = github;
  }
}

//PROJECTS
const uniroom = new Project(
  "2020",
  "UniRoom",
  [
    "Swift",
    "Nodejs",
    "Express",
    "AWS",
    "Rest API",
    "Database Management",
    "Firebase",
    "Authentication",
  ],
  "https://apps.apple.com/us/app/uniroom-roommate-finder/id1528826613",
  "View App"
);

const worldle = new Project(
  "2022",
  "World-le",
  ["React", "Rest API", "Javascript", "CSS", "Axios", "Redux"],
  "https://colbyb2.github.io/worldle",
  "Visit Site",
  "https://github.com/colbyb2/worldle"
);

const nutrico = new Project(
  "2022",
  "Nutrico",
  [
    "React",
    "Nodejs",
    "Express",
    "CSS",
    "Firebase",
    "Authentication",
    "ChartJS",
  ],
  "https://www.youtube.com/watch?v=I_r-QMSNeB8",
  "View Demo",
  "https://github.com/colbyb2/nutrico"
);

const phoenix = new Project(
  "2023",
  "Phoenix",
  ["Python", "OpenAI API", "CLI Binary"],
  "",
  "",
  "https://github.com/colbyb2/phoenix"
);

const reactCalendar = new Project(
  "2022",
  "React Calendar",
  ["React", "Nodejs", "Date-fns", "CSS"],
  "",
  "",
  "https://github.com/colbyb2/react-calendar"
);

const betterNetwork = new Project(
  "2023",
  "Better Network",
  ["Swift", "SPM", "API Design"],
  "",
  "",
  "https://github.com/colbyb2/BetterNetwork"
);

const swiftyPopups = new Project(
  "2023",
  "SwiftyPopups",
  ["SwiftUI", "SPM", "API Design"],
  "",
  "",
  "https://github.com/colbyb2/SwiftyPopups"
);

const gitRanger = new Project(
  "2023",
  "GitRanger",
  [
    "Python",
    "Docker",
    "AWS",
    "System Design",
    "FastAPI",
    "CI/CD Pipeline",
    "Cybersecurity",
  ],
  "",
  ""
);

const projects: Project[] = [
  uniroom,
  worldle,
  nutrico,
  gitRanger,
  phoenix,
  reactCalendar,
  betterNetwork,
  swiftyPopups,
];

export { Project, projects };
