import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-text">Colby Brown ©2023</div>
    </div>
  );
}
