import React from "react";
import "./Projects.css";
import SkillBubble from "../../Components/SkillBubble/SkillBubble";
import { FiArrowUpRight, FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Projects() {
  return (
    <div className="projects-section">
      <h3 className="projects-title">Key Projects</h3>
      <ProjectCard
        name="UniRoom"
        description="Built a fullstack iOS App for finding college roommates. Users
        enter their school and fill out a survery about their living preferences; 
        from room temperature to sleep schedule, every detail is accounted for. Allows 
        users to match with potential roommates and then chat right within the app."
        link="https://apps.apple.com/us/app/uniroom-roommate-finder/id1528826613"
        skills={[
          "Swift",
          "Nodejs",
          "Express",
          "AWS",
          "Rest API",
          "Database Management",
          "Firebase",
          "Authentication",
        ]}
      />
      <ProjectCard
        name="World-le"
        description="Web application country guessing game. Guess countries 
        and use the provided clues to get the right answer. Guess correctly 
        within 7 tries or you lose, try to get the highest possible streak!"
        link="https://colbyb2.github.io/worldle"
        skills={["React", "Rest API", "Javascript", "CSS", "Axios", "Redux"]}
      />
      <ProjectCard
        name="Nutrico"
        description="Developed a full stack web app to allow users to create a food journal, quickly view analyzed data, and
        find food sensitives. Log your food and ingredients as you eat and let the algoirthm do 
        the rest. Overtime, the app will compile your data into nice charts that show which foods are correlated with 
        symptoms. The charts and graphs are heavily customizable using filters to give you the most detailed 
        data."
        link="https://www.youtube.com/watch?v=I_r-QMSNeB8"
        skills={[
          "React",
          "Nodejs",
          "Express",
          "CSS",
          "Firebase",
          "Authentication",
          "ChartJS",
        ]}
      />
      <Link to="/portfolio">
        <div className="project-footer">
          <h4 className="project-footer-text">View full portfolio</h4>
          <FiArrowRight className="project-footer-arrow" />
        </div>
      </Link>
    </div>
  );
}

type ProjectCardProps = {
  name: string;
  description: string;
  link: string;
  skills: string[];
};

function ProjectCard(props: ProjectCardProps) {
  return (
    <a href={props.link}>
      <div className="project-card">
        <div className="project-card-title">
          <div className="project-card-primary">{props.name}</div>
          <FiArrowUpRight className="project-card-link" />
        </div>
        <div className="project-card-description">{props.description}</div>
        <div className="project-card-skills">
          {props.skills.map((skill) => {
            return <SkillBubble key={skill} skill={skill} />;
          })}
        </div>
      </div>
    </a>
  );
}
