import React from "react";
import "./SkillBubble.css";

type SkillBubbleProps = {
  skill: string;
};

export default function SkillBubble(props: SkillBubbleProps) {
  return <div className="skillbubble">{props.skill}</div>;
}
