import React from "react";
import "./Contact.css";
import { MdEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";

export default function Contact() {
  return (
    <div className="contact-section">
      <h2>Contact</h2>
      <a href="mailto:csbrown10@gmail.com">
        <div className="contact-row">
          <MdEmail />
          <div className="contact-label">Email</div>
        </div>
      </a>
      <a href="https://www.linkedin.com/in/colby-brown-wisconsin/">
        <div className="contact-row">
          <FaLinkedin />
          <div className="contact-label">LinkedIn</div>
        </div>
      </a>
    </div>
  );
}
