import React from "react";
import "./Experience.css";
import SkillBubble from "../../Components/SkillBubble/SkillBubble";

export default function Experience() {
  return (
    <div className="experience-section">
      <h3 className="experience-title">Experience</h3>
      <ExperienceCard
        timeline="2023 - Present"
        company="Capital One"
        position="Software Engineer Intern"
        description="Developing a robust, scalable bot that navigates the vast landscape of
        organizational repositories, identifying potential compliance and
        vulnerability risks. The bot has the ability to scan thousands of
        repositories and organizations throughout the company on a regular
        basis, providing a boost in both security and code quality."
        skills={[
          "Python",
          "Docker",
          "AWS",
          "System Design",
          "FastAPI",
          "CI/CD Pipeline",
          "Cybersecurity",
        ]}
      />
    </div>
  );
}

type ExperienceCardProps = {
  timeline: string;
  company: string;
  position: string;
  description: string;
  skills: string[];
};

function ExperienceCard(props: ExperienceCardProps) {
  return (
    <div className="experience-card">
      <div className="experience-card-secondary">{props.timeline}</div>
      <div className="experience-card-primary">{props.company}</div>
      <div className="experience-card-secondary">{props.position}</div>
      <p>{props.description}</p>
      <div className="experience-card-skills">
        {props.skills.map((skill) => {
          return <SkillBubble key={skill} skill={skill} />;
        })}
      </div>
    </div>
  );
}
