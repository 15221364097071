import React from "react";
import "./Home.css";
import { FaLinkedin, FaGithubSquare } from "react-icons/fa";
import About from "./About";
import Experience from "./Experience";
import Projects from "./Projects";
import Contact from "./Contact";

function Home() {
  return (
    <div id="home-page">
      {/* Top Section */}
      <div className="top-section">
        <h1 className="title gradient-text fade-in">Colby Brown</h1>
        <h3 className="subtitle">Fullstack Software Engineer</h3>
        <div className="social-container">
          <a href="https://www.linkedin.com/in/colby-brown-wisconsin/">
            <FaLinkedin className="social-link" />
          </a>
          <a href="https://github.com/colbyb2">
            <FaGithubSquare className="social-link" />
          </a>
        </div>
      </div>
      <div id="other-sections">
        <About />
        <Experience />
        <Projects />
        <Contact />
      </div>
    </div>
  );
}

export default Home;
