import React from "react";
import "./About.css";

export default function About() {
  return (
    <div className="about-section">
      <h3 className="about-title">About</h3>
      <p className="about-paragraph">
        I'm currently a senior at The University of Wisconsin-Madison studying
        Computer Science. I have a passion for problem-solving and creating
        seamless user experiences across various platforms. As a software
        engineer specializing in backend, web, and mobile development, I blend
        theory and practice to design and implement robust, scalable, and
        innovative solutions.
      </p>
    </div>
  );
}
