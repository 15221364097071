import React from "react";
import "./Portfolio.css";
import SkillBubble from "../../Components/SkillBubble/SkillBubble";
import { FaGithubSquare } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";
import { projects } from "../../Objects/Project";

export default function Portfolio() {
  return (
    <div className="portfolio">
      <h1 className="portfolio-title">All Projects</h1>
      <table>
        <thead>
          <tr>
            <th>Year</th>
            <th>Project</th>
            <th>Utilized</th>
            <th>Link</th>
            <th>GitHub</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => {
            return (
              <tr>
                <td>{project.year}</td>
                <td>{project.name}</td>
                <td>
                  <SkillSet skills={project.skills} />
                </td>
                <td>
                  {project.link !== "" ? (
                    <a href={project.link}>
                      <div>{project.linkText}</div>
                      <FiArrowUpRight />
                    </a>
                  ) : null}
                </td>
                <td>
                  {project.github !== null ? (
                    <a href={project.github}>
                      <FaGithubSquare className="portfolio-github-link" />
                    </a>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

type SkillSetProps = {
  skills: string[];
};

function SkillSet(props: SkillSetProps) {
  return (
    <div className="portfolio-skillset">
      {props.skills.map((skill) => {
        return <SkillBubble key={skill} skill={skill} />;
      })}
    </div>
  );
}
