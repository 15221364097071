import React from "react";
import { useEffect, useState } from "react";
import "./NavBar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function NavBar() {
  const FOLDWIDTH = 650;
  const [showNavbar, setShowNavbar] = useState(false);
  const [manualShowNav, setManualShowNav] = useState(false);

  //Adds listener to track window width
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= FOLDWIDTH) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    const navbar = document.getElementById("navbar-container");
    if (navbar != null && window.innerWidth >= FOLDWIDTH) {
      navbar.classList.add("initial-load");
      setTimeout(() => {
        setShowNavbar(true);
        navbar.classList.remove("initial-load");
        navbar.classList.add("after-load");
      }, 3000);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div id="navbar-wrapper">
      <div className="navbar-burger-wrapper">
        {!manualShowNav ? (
          <GiHamburgerMenu
            className="navbar-burger"
            onClick={() => {
              setManualShowNav(true);
            }}
          />
        ) : (
          <AiOutlineClose
            className="navbar-burger"
            onClick={() => {
              setManualShowNav(false);
            }}
          />
        )}
      </div>
      <div
        id="navbar-container"
        className={`${showNavbar || manualShowNav ? "" : "navbar-hide"}`}
      >
        <Link to="/">
          <div className={"nav-option"}>Home</div>
        </Link>
        <Link to="portfolio">
          <div className={"nav-option"}>Portfolio</div>
        </Link>
        <Link to="/">
          <div
            className={"nav-option"}
            onClick={() => {
              window.scrollTo(0, document.body.scrollHeight);
            }}
          >
            Contact
          </div>
        </Link>
      </div>
    </div>
  );
}
